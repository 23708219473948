import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import ArrowKeysReact from 'arrow-keys-react';
import axios from 'axios';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SlideButton from './SlideButton';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 700,
    backgroundColor: '#282c34',
    position: 'relative',
    overflow: 'auto',
    height: '500px',
    margin: 20,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  card: {
    minWidth: 375,
    margin: 12,
    whiteSpace: 'pre-wrap',
    fontSize: 20,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

/*
let data = `
~每當我們同心敬拜 專輯：頌恩旋律 - 在恩典中敬拜（王者文化）
曲詞：周敏曦 編：周淦棪|
每當我們同心敬拜，來讓全世界知道
我主極美善尊貴，榮光裡聖潔無瑕|
每當我們同心敬拜，憑熱誠讚美頌揚
令世上各方驚嘆，神恩惠奇異無價|
同頌讚主聖名，舉起手和應
同站恩典之中，倚靠救主誇勝|
同頌讚主聖名，一心稱謝頌揚
同回應主恩深愛未泯|
（同回應主恩深愛未泯 x2）
 |
~【天地讚美】詩集：你是王，4
版權屬 香港基督徒音樂事工協會（ACM） 所有|
地與海奏出歌韻，磐石唱讚美歌聲，
風雨電也歌頌，雲霧同頌吹送，|
全地奏響聲勢無比。
 |
頌讚聲永不衰竭，全地要敬拜事奉，
高唱萬有之上，榮耀無限之處，|
全屬我主尊貴耶穌所有。
 |
讓讚美如風天空海闊飛，
讓讚美浪花般海中濺起。|
高唱萬有之上，榮耀無限之處，
全屬我主尊貴耶穌所有！
`;

var str = data.split('|');*/
var count = 0;



const App = () => {

  const classes = useStyles();

  const cleanLine = (data) => {
    let line = data;

    /*
    if (line[0] == '\n\r') {
      console.log('yes');
      line = line.replace('\n', ''); // this only replace the first matching occurrence
    }*/
    return line.trim();
  }

  const detectTitleLine = (data) => {
    console.log('detectTitleLine: ' + data);
    if (data[0] == '~') {
      console.log('true');
      return true;
    } else {
      console.log('false');
      return false;
    }
  }

  const cleanTitleLine = (data) => {

    if (data[0] == '~') {
      data = data.replace('~', ''); // this only replace the first matching occurrence
    }

    return data;
  }

  //const [content, setContent] = useState(cleanLine(str[0]));
  const [data, setData] = useState([]);
  const [content, setContent] = useState('');
  const [titleSlide, setTitleSlide] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [blankScreen, setBlankScreen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // https://mnaoumov.wordpress.com/2018/02/05/download-google-drive-file-via-ajax-ignoring-cors/
      // https://sites.google.com/site/gdocs2direct/
      const result = await axios(
        //'https://cors-anywhere.herokuapp.com/https://drive.google.com/uc?export=download&id=1HeXvd28FlOD4558qGKSiWxpL0xkBqOCj',
        //'https://www.googleapis.com/drive/v3/files/1D5EA8tGSpcE6_MAzNRdJwQ1bI8ZQu0Df?alt=media&key=AIzaSyBpoUXpStN10uhw_5VpGgWv4Cx4wbhYWUA'
        'https://cors-anywhere.herokuapp.com/https://drive.google.com/u/0/uc?id=1D5EA8tGSpcE6_MAzNRdJwQ1bI8ZQu0Df&export=download'
        //'https://www.googleapis.com/drive/v2/files/1HeXvd28FlOD4558qGKSiWxpL0xkBqOCj?alt=media&source=downloadUrl'
        //https://drive.google.com/file/d/1HeXvd28FlOD4558qGKSiWxpL0xkBqOCj/view?usp=sharing

        //'https://drive.google.com/uc?export=download&id=1HeXvd28FlOD4558qGKSiWxpL0xkBqOCj'
      );

      console.log(result.data);
      let t = result.data.replace(/\r\n/g, '\n');

      let splitData = t.split('|')

      setData(splitData);


      let line = cleanLine(splitData[0]);


      let isTitle = detectTitleLine(line);
      setTitleSlide(isTitle);

      if (isTitle) {
        setContent(cleanTitleLine(line));
      } else {
        setContent(line);
      }
    };

    //fetchData();
  }, []);


  /*
  ArrowKeysReact.config({
    left: () => {
      console.log('left key detected.');

      if ((count - 1) >= 0) {

        count = count - 1;
        console.log('count: ' + count);

        let line = cleanLine(data[count]);

        let isTitle = detectTitleLine(line);
        setTitleSlide(isTitle);

        if (isTitle) {
          setContent(cleanTitleLine(line));
        } else {
          setContent(line);
        }
      }

    },
    right: () => {
      console.log('right key detected.');

      if ((count + 1) < data.length) {
        count = count + 1;
        console.log('count: ' + count);

        let line = cleanLine(data[count]);

        let isTitle = detectTitleLine(line);
        setTitleSlide(isTitle);

        if (isTitle) {
          setContent(cleanTitleLine(line));
        } else {
          setContent(line);
        }
      }
    },
  });*/

  const showFile = async (e) => {
    console.log(e.target.files);
    e.preventDefault()

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = (e.target.result)
      console.log(text)
      //alert(text)

      // clean file

      let t = text.replace(/\r\n/g, '\n');

      let splitData = t.split('|')

      setData(splitData);

      let line = cleanLine(splitData[0]);


      let isTitle = detectTitleLine(line);
      setTitleSlide(isTitle);

      if (isTitle) {
        setContent(cleanTitleLine(line));
      } else {
        setContent(line);
      }

      setFileLoaded(true);
    };

    if (e.target.files.length > 0) {
      reader.readAsText(e.target.files[0]);

    } else {
      // cancelled file selection, resetting all states
      setFileLoaded(false);
      setBlankScreen(false);
      setData([]);
      setContent('');
      setTitleSlide(false);
    }

  }

  const handleKey = (key, e) => {
    console.log(`do something ${key}`);

    console.log(`data length ${data.length}`);
    console.log('fileLoaded: ' + fileLoaded);
    console.log('blankScreen: ' + blankScreen);

    if (fileLoaded === false)
      return;

    if (key == 'left') {
      console.log('left key detected.');

      if ((count - 1) >= 0) {

        count = count - 1;

        getSlide(count);
        setBlankScreen(false);
      }

    } else if (key == 'right') {
      console.log('right key detected.');

      if ((count + 1) < data.length) {
        count = count + 1;

        getSlide(count);
        setBlankScreen(false);
      }

    } else if (key == 'b') {

      if (blankScreen === false) {
        setContent('');
        setBlankScreen(true);
      } else {
        getSlide(count);
        setBlankScreen(false);
      }
    }
  }

  const getSlide = (count) => {
    let line = cleanLine(data[count]);

    let isTitle = detectTitleLine(line);
    setTitleSlide(isTitle);

    if (isTitle) {
      setContent(cleanTitleLine(line));
    } else {
      setContent(line);
    }
  }

  const jumpToSlide = (slideNumber) => {

    count = slideNumber;
    getSlide(slideNumber);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="InfoHeader">
        {blankScreen ? 'BLANKSCREEN' : ''}
        </div>
        <div className="Content">
          <div className="FileInput">
            <input type="file" onChange={(e) => showFile(e)} />
          </div>
          <div>
            {fileLoaded === true ?
              <List component="nav" aria-label="secondary mailbox folders" className={classes.root}>

                {data.map((row, index) => (
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h5" component="p">
                        {row}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => jumpToSlide(index)}>Select</Button>
                    </CardActions>
                  </Card>
                )
                )}
              </List> : ''}
          </div>

        </div>
        <div className="Footer">
          <div className={titleSlide ? 'Title' : 'Lyrics'}>
            <span className={titleSlide ? 'TitleSpan' : 'LyricsSpan'}>{content}</span>
          </div>
        </div>
      </header>
      <KeyboardEventHandler handleKeys={['all']} onKeyEvent={(key, e) => handleKey(key, e)} />
    </div>
  );
}

export default App;
